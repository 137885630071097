import { SbEditableBlok } from '@storyblok/storyblok-editable'
import * as React from 'react'

const attachStoryblokBridge = async () => {
  return new Promise<any | false>(resolve => {
    const script = document.createElement('script')
    script.src = `//app.storyblok.com/f/storyblok-v2-latest.js`
    script.id = 'storyblokBridge'
    document.body.appendChild(script)

    script.onload = () => {
      const { StoryblokBridge }: { StoryblokBridge: any } = window as any

      if (typeof StoryblokBridge !== 'undefined') {
        const instance = new StoryblokBridge()
        resolve(instance)
      }

      resolve(false)
    }
  })
}

export interface StoryblokContextValue {
  bridge: any
}

export const StoryblokContext = React.createContext<{
  bridge: any
}>({
  bridge: undefined,
})

export const useStoryblokBridge = () => {
  const { bridge } = React.useContext(StoryblokContext)
  return bridge
}

export const StoryblokProvider = ({ children }: { children: React.ReactNode }) => {
  const [bridge, setBridge] = React.useState<any>(undefined)

  React.useEffect(() => {
    if (location && location.search.includes('_storyblok')) {
      const existingScript = document.getElementById('storyblokBridge')
      if (!!existingScript) return

      attachStoryblokBridge().then(bridge => setBridge(bridge))
    }
  }, [])

  // attach updates for stories
  React.useEffect(() => {
    if (bridge) {
      bridge.on(['published', 'change'], () => {
        window.location.reload()
      })
    }
  }, [bridge])

  return <StoryblokContext.Provider value={{ bridge }}>{children}</StoryblokContext.Provider>
}

export const StoryblokConsumer = StoryblokContext.Consumer
