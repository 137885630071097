declare global {
  interface Window {
    dataLayer: any[]
  }
}

export interface TrackingContextValues {
  clearAllTrackingVariables: () => void
  clearTrackingVariable: (variable: DataLayerVariables) => void
  setTrackingVariables: (payload: { [key in DataLayerVariables]?: string }) => void
  triggerEvent: (event: DataLayerEvents) => void
  variables: IDLPageViewVariables | {}
}


// Make sure to add triggers for these in GTM if adding new ones
export enum DataLayerEvents {
  PAGE_VIEW = 'page_view',
  NEWSLETTER_SUBSCRIBED = 'newsletter_subscribed',
  CONTACT_FORM_SUBMITTED = 'contact_form_submitted',
  USER_SIGNED_UP = 'user_signed_up',
  USER_LOGGED_IN = 'user_logged_in',
  PRODUCT_KIT_DOWNLOADED = 'product_kit_downloaded',
  PRODUCT_KIT_VIEWED = 'product_kit_viewed',
  LICENSE_KEY_DOWNLOADED = 'license_key_loaded',

  GRANT_AD_CONSENT = 'advertisement__grant_consent',
  REJECT_AD_CONSENT = 'advertisement__reject_consent',
  GRANT_ANALYTICS_CONSENT = 'analytics__grant_consent',
  REJECT_ANALYTICS_CONSENT = 'analytics__reject_consent',

  COOKIE_BANNER_READY = 'cookie-banner-ready',

  VIEWER_LAUNCH_DEMO = 'viewer_launch_demo',
}

export enum DataLayerVariables {
  CONTACT_EMAIL = 'contact_email',
  CONTACT_FIRST_NAME = 'contact_first_name',
  CONTACT_LAST_NAME = 'contact_last_name',
  CONTACT_COMPANY = 'contact_company',
  CONTACT_REASON = 'contact_reason',
  CONTACT_COUNTRY = 'contact_country',
  CONTACT_MESSAGE = 'contact_message',
  PRODUCT_CATEGORY = 'product_category',
  PRODUCT_NAME = 'product_name',
  PRODUCT_OS = 'product_os',
  PAGE_CATEGORY = 'page_category',
  EVENT = 'event',
  VISITOR_ID = 'visitor_id', // if possible
  USER_ID = 'user_id',
  USER_COMPANY = 'user_company',
  USER_EMAIL = 'user_email',
  USER_BUSINESS_ROLE = 'user_business_role',
  USER_MKT_COM_CONSENT = 'user_mkt_com_consent',
  USER_FIRST_REFERRAL = 'user_first_referral',
  USER_TYPE = 'user_type',
  USER_OPERATING_SYSTEM = 'user_operating_system',
  CONTACT_ROLE = 'contact_role',
  CONTACT_PHONE = 'contact_phone',

  DOWNLOAD_PRODUCT_NAME = 'download_product_name',
}

export type DataLayerVariableType = string | number | 'NA'

export enum DataLayerUserType {
  REG = 'reg',
  LOGIN = 'login',
}

export enum TrackingActions {
  SET_VARIABLES = 'SET_VARIABLES',
  CLEAR_TRACKING_VALUES = 'CLEAR_TRACKING_VALUES',
  TRIGGER_EVENT = 'TRIGGER_EVENT',
  CLEAR_TRACKING_VARIABLE = 'CLEAR_TRACKING_VARIABLE',
}

export type DataLayerObject = { [key in DataLayerVariables]?: DataLayerVariableType }

export interface ITrackingActions {
  type: TrackingActions
  payload?: DataLayerObject | Function | string
}

// ############################## Events ##############################

// Event - Page view
export interface IDLPageViewVariables {
  [DataLayerVariables.PAGE_CATEGORY]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_CATEGORY]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_NAME]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_OS]?: DataLayerVariableType
  [DataLayerVariables.VISITOR_ID]?: DataLayerVariableType
}

export interface IDLPageViewEvent extends IDLPageViewVariables {
  [DataLayerVariables.EVENT]: DataLayerEvents.PAGE_VIEW
}

// Event - Newsletter subscribed
export interface IDLNewsletterSubscribedEvent {
  [DataLayerVariables.EVENT]: DataLayerEvents.NEWSLETTER_SUBSCRIBED
  [DataLayerVariables.CONTACT_EMAIL]: string
}

// Event - Contact Form
export interface IDLContactFormEvent {
  [DataLayerVariables.EVENT]: DataLayerEvents.CONTACT_FORM_SUBMITTED
  [DataLayerVariables.CONTACT_FIRST_NAME]: DataLayerVariableType
  [DataLayerVariables.CONTACT_LAST_NAME]: DataLayerVariableType
  [DataLayerVariables.CONTACT_COMPANY]: DataLayerVariableType
  [DataLayerVariables.CONTACT_EMAIL]: DataLayerVariableType
  [DataLayerVariables.CONTACT_REASON]: DataLayerVariableType
  [DataLayerVariables.CONTACT_COUNTRY]?: DataLayerVariableType // is this still needed in the dl?
  [DataLayerVariables.CONTACT_MESSAGE]: DataLayerVariableType
}

// Event - User signed up
export interface IDLUserSignedUpEvent {
  [DataLayerVariables.EVENT]: DataLayerEvents.USER_SIGNED_UP
  [DataLayerVariables.USER_ID]?: DataLayerVariableType
  [DataLayerVariables.USER_EMAIL]: DataLayerVariableType
  [DataLayerVariables.USER_BUSINESS_ROLE]: DataLayerVariableType
  [DataLayerVariables.USER_COMPANY]: DataLayerVariableType
  [DataLayerVariables.USER_MKT_COM_CONSENT]: DataLayerVariableType
  [DataLayerVariables.USER_FIRST_REFERRAL]: DataLayerVariableType
  [DataLayerVariables.USER_OPERATING_SYSTEM]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_CATEGORY]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_NAME]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_OS]: DataLayerVariableType
}

// Event - User logged in
export interface IDLUserLoggedInEvent {
  [DataLayerVariables.EVENT]: DataLayerEvents.USER_LOGGED_IN
  [DataLayerVariables.USER_ID]?: DataLayerVariableType
  [DataLayerVariables.USER_EMAIL]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_CATEGORY]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_NAME]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_OS]: DataLayerVariableType
}

// Event - Product kit downloaded
export interface IDLProductKitDownloadedEvent {
  [DataLayerVariables.EVENT]: DataLayerEvents.PRODUCT_KIT_DOWNLOADED
  [DataLayerVariables.PRODUCT_CATEGORY]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_NAME]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_OS]: DataLayerVariableType
  [DataLayerVariables.USER_ID]?: DataLayerVariableType
  [DataLayerVariables.USER_TYPE]: DataLayerUserType

  [DataLayerVariables.DOWNLOAD_PRODUCT_NAME]: DataLayerVariableType
}

// Event - Product kit viewed
export interface IDLProductKitViewedEvent {
  [DataLayerVariables.EVENT]: DataLayerEvents.PRODUCT_KIT_VIEWED
  [DataLayerVariables.PRODUCT_CATEGORY]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_NAME]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_OS]: DataLayerVariableType
  [DataLayerVariables.USER_TYPE]: DataLayerUserType
  [DataLayerVariables.USER_ID]?: DataLayerVariableType
}

// Event - License key downloaded
export interface IDLLicenseKeyLoadedEvent {
  [DataLayerVariables.EVENT]: DataLayerEvents.LICENSE_KEY_DOWNLOADED
  [DataLayerVariables.USER_ID]?: DataLayerVariableType
  [DataLayerVariables.USER_TYPE]: DataLayerUserType
  [DataLayerVariables.PRODUCT_CATEGORY]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_NAME]: DataLayerVariableType
  [DataLayerVariables.PRODUCT_OS]: DataLayerVariableType
}
