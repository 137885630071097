export const formatPath = (url?: string) => {
  if (url === undefined) return
  const urlAsArray = urlStringToArray(url) || []

  return urlArrayToString(urlAsArray) || '/'
}

export const urlStringToArray = (url?: string): string[] | undefined => {
  if (!url) return

  const urlArray = url.match(/[^\/]*(?:[^\/])/g)

  if (!urlArray) return

  return urlArray
}
export const urlArrayToString = (urlAsArray: string[]) =>
  urlAsArray.reduce((acc, curr) => {
    if (curr?.length > 0) {
      return acc + '/' + curr
    }
    return acc
  }, '')
