exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-overview-template-index-tsx": () => import("./../../../src/templates/BlogOverviewTemplate/index.tsx" /* webpackChunkName: "component---src-templates-blog-overview-template-index-tsx" */),
  "component---src-templates-blog-post-template-index-tsx": () => import("./../../../src/templates/BlogPostTemplate/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-template-index-tsx" */),
  "component---src-templates-error-page-template-tsx": () => import("./../../../src/templates/ErrorPageTemplate.tsx" /* webpackChunkName: "component---src-templates-error-page-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/PageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-product-facts-features-template-tsx": () => import("./../../../src/templates/ProductFactsFeaturesTemplate.tsx" /* webpackChunkName: "component---src-templates-product-facts-features-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}

