import {
  DataLayerEvents,
  DataLayerVariables,
  IDLContactFormEvent,
  IDLLicenseKeyLoadedEvent,
  IDLNewsletterSubscribedEvent,
  IDLPageViewEvent,
  IDLProductKitDownloadedEvent,
  IDLProductKitViewedEvent,
  IDLUserLoggedInEvent,
  IDLUserSignedUpEvent,
} from './interfaces'

export const getContactFormSubmittedPayload = (variables): IDLContactFormEvent => ({
  [DataLayerVariables.EVENT]: DataLayerEvents.CONTACT_FORM_SUBMITTED,
  [DataLayerVariables.CONTACT_FIRST_NAME]: variables[DataLayerVariables.CONTACT_FIRST_NAME],
  [DataLayerVariables.CONTACT_LAST_NAME]: variables[DataLayerVariables.CONTACT_LAST_NAME],
  [DataLayerVariables.CONTACT_COMPANY]: variables[DataLayerVariables.CONTACT_COMPANY],
  [DataLayerVariables.CONTACT_EMAIL]: variables[DataLayerVariables.CONTACT_EMAIL],
  [DataLayerVariables.CONTACT_REASON]: variables[DataLayerVariables.CONTACT_REASON],
  [DataLayerVariables.CONTACT_MESSAGE]: variables[DataLayerVariables.CONTACT_MESSAGE],
})

export const getLicenseKeyLoadedPayload = (variables): IDLLicenseKeyLoadedEvent => ({
  [DataLayerVariables.EVENT]: DataLayerEvents.LICENSE_KEY_DOWNLOADED,
  [DataLayerVariables.PRODUCT_CATEGORY]: variables[DataLayerVariables.PRODUCT_CATEGORY],
  [DataLayerVariables.PRODUCT_NAME]: variables[DataLayerVariables.PRODUCT_NAME],
  [DataLayerVariables.PRODUCT_OS]: variables[DataLayerVariables.PRODUCT_OS],
  [DataLayerVariables.USER_ID]: variables[DataLayerVariables.USER_ID],
  [DataLayerVariables.USER_TYPE]: variables[DataLayerVariables.USER_TYPE],
})

export const getNewsletterSubscribedPayload = (variables): IDLNewsletterSubscribedEvent => ({
  [DataLayerVariables.EVENT]: DataLayerEvents.NEWSLETTER_SUBSCRIBED,
  [DataLayerVariables.CONTACT_EMAIL]: variables[DataLayerVariables.CONTACT_EMAIL],
})

export const getPageViewPayload = (variables): IDLPageViewEvent => ({
  [DataLayerVariables.EVENT]: DataLayerEvents.PAGE_VIEW,
  [DataLayerVariables.PRODUCT_CATEGORY]: variables[DataLayerVariables.PRODUCT_CATEGORY],
  [DataLayerVariables.PRODUCT_NAME]: variables[DataLayerVariables.PRODUCT_NAME],
  [DataLayerVariables.PAGE_CATEGORY]: variables[DataLayerVariables.PAGE_CATEGORY],
  [DataLayerVariables.PRODUCT_OS]: variables[DataLayerVariables.PAGE_CATEGORY] || 'NA',
  // [DataLayerVariables.VISITOR_ID]: 'NA', // for now it cannot be set on page level
})

export const getProductKitDownloadedPayload = (variables): IDLProductKitDownloadedEvent => ({
  [DataLayerVariables.EVENT]: DataLayerEvents.PRODUCT_KIT_DOWNLOADED,
  [DataLayerVariables.PRODUCT_CATEGORY]: variables[DataLayerVariables.PRODUCT_CATEGORY],
  [DataLayerVariables.PRODUCT_NAME]: variables[DataLayerVariables.PRODUCT_NAME],
  [DataLayerVariables.PRODUCT_OS]: variables[DataLayerVariables.PRODUCT_OS],
  [DataLayerVariables.USER_ID]: variables[DataLayerVariables.USER_ID],
  [DataLayerVariables.USER_TYPE]: variables[DataLayerVariables.USER_TYPE],

  [DataLayerVariables.DOWNLOAD_PRODUCT_NAME]: variables[DataLayerVariables.DOWNLOAD_PRODUCT_NAME],
})

export const getProductKitViewedPayload = (variables): IDLProductKitViewedEvent => ({
  [DataLayerVariables.EVENT]: DataLayerEvents.PRODUCT_KIT_VIEWED,
  [DataLayerVariables.PRODUCT_CATEGORY]: variables[DataLayerVariables.PRODUCT_CATEGORY] || 'NA',
  [DataLayerVariables.PRODUCT_NAME]: variables[DataLayerVariables.PRODUCT_NAME] || 'NA',
  [DataLayerVariables.PRODUCT_OS]: variables[DataLayerVariables.PRODUCT_OS],
  [DataLayerVariables.USER_TYPE]: variables[DataLayerVariables.USER_TYPE] || 'NA',
  [DataLayerVariables.USER_ID]: variables[DataLayerVariables.USER_ID] || 'NA',
})

export const getUserLoggedInPayload = (variables): IDLUserLoggedInEvent => ({
  [DataLayerVariables.EVENT]: DataLayerEvents.USER_LOGGED_IN,
  [DataLayerVariables.PRODUCT_CATEGORY]: variables[DataLayerVariables.PRODUCT_CATEGORY],
  [DataLayerVariables.PRODUCT_NAME]: variables[DataLayerVariables.PRODUCT_NAME],
  [DataLayerVariables.PRODUCT_OS]: variables[DataLayerVariables.PRODUCT_OS],
  [DataLayerVariables.USER_ID]: variables[DataLayerVariables.USER_ID],
  [DataLayerVariables.USER_EMAIL]: variables[DataLayerVariables.USER_EMAIL],
})

export const getUserSignedUpPayload = (variables): IDLUserSignedUpEvent => ({
  [DataLayerVariables.EVENT]: DataLayerEvents.USER_SIGNED_UP,
  [DataLayerVariables.PRODUCT_CATEGORY]: variables[DataLayerVariables.PRODUCT_CATEGORY],
  [DataLayerVariables.PRODUCT_NAME]: variables[DataLayerVariables.PRODUCT_NAME],
  [DataLayerVariables.PRODUCT_OS]: variables[DataLayerVariables.PRODUCT_OS],
  [DataLayerVariables.USER_ID]: variables[DataLayerVariables.USER_ID],
  [DataLayerVariables.USER_EMAIL]: variables[DataLayerVariables.USER_EMAIL],
  [DataLayerVariables.USER_BUSINESS_ROLE]: variables[DataLayerVariables.USER_BUSINESS_ROLE],
  [DataLayerVariables.USER_COMPANY]: variables[DataLayerVariables.USER_COMPANY],
  [DataLayerVariables.USER_MKT_COM_CONSENT]: variables[DataLayerVariables.USER_MKT_COM_CONSENT],
  [DataLayerVariables.USER_FIRST_REFERRAL]: variables[DataLayerVariables.USER_FIRST_REFERRAL],
  [DataLayerVariables.USER_OPERATING_SYSTEM]: variables[DataLayerVariables.USER_OPERATING_SYSTEM],
})
