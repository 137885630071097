import { isFunction } from 'lodash'
import { track } from '../../utils/tracking'
import { DataLayerObject, ITrackingActions, TrackingActions } from './interfaces'

export const trackingReducer = (state, action: ITrackingActions) => {
  switch (action.type) {
    case TrackingActions.SET_VARIABLES: {
      const newState = { ...state }
      const payload = action.payload

      if (typeof payload === 'object' && !isFunction(payload)) {
        newState.variables = { ...state.variables, ...payload }
      }

      return newState
    }
    case TrackingActions.CLEAR_TRACKING_VALUES: {
      const newState = { ...state }
      const payload = action.payload

      if (typeof payload === 'string') {
        delete newState.variables[payload]
      }

      return newState
    }
    case TrackingActions.TRIGGER_EVENT: {
      const variables = { ...state.variables }
      const datalayerPayloadGetter = action.payload
      let dataLayerPayload: DataLayerObject = {}

      if (isFunction(datalayerPayloadGetter)) {
        dataLayerPayload = datalayerPayloadGetter(variables)
        track(dataLayerPayload)
      }

      return state
    }
    default:
      return state
  }
}
