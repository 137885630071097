import { navigate } from 'gatsby'
import { snakeCase } from 'lodash'
import { DataLayerVariables, DataLayerVariableType } from '../context/TrackingContext/interfaces'

export const track = (...dataLayerPayload: Array<unknown>): void => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(...dataLayerPayload)
}

export const navigateAndTrack = (to, data) => {
  track(data)
  navigate(to)
}

const VARIABLES_WITHOUT_SNAKE_CASE: DataLayerVariables[] = [
  DataLayerVariables.USER_MKT_COM_CONSENT,
  DataLayerVariables.USER_FIRST_REFERRAL,
  DataLayerVariables.USER_EMAIL,
  DataLayerVariables.CONTACT_EMAIL,
]

export const processDatalayerPayload = (payload: { [key in DataLayerVariables]?: DataLayerVariableType }) => {
  const processedPayload = { ...payload }
  if (processedPayload[DataLayerVariables.EVENT]) delete processedPayload[DataLayerVariables.EVENT]

  Object.entries(processedPayload).forEach(([key, value]) => {
    if (!value) {
      processedPayload[key] = 'NA'
      return
    }

    if (
      !VARIABLES_WITHOUT_SNAKE_CASE.includes(key as DataLayerVariables) &&
      typeof value === 'string' &&
      value !== 'NA'
    ) {
      processedPayload[key] = snakeCase(value)
      return
    }
  })

  return processedPayload
}

export const getUserOperatingSystem = () => {
  let operatingSystem = 'NA'
  if (navigator) {
    if (navigator.userAgent.indexOf('Win') != -1) operatingSystem = 'Windows'
    if (navigator.userAgent.indexOf('Mac') != -1) operatingSystem = 'MacOS'
    if (navigator.userAgent.indexOf('X11') != -1) operatingSystem = 'UNIX'
    if (navigator.userAgent.indexOf('Linux') != -1) operatingSystem = 'Linux'
  }
  return operatingSystem
}
