import React, { useEffect } from 'react';

const LinkedInInsightTag: React.FC = () => {
    useEffect(() => {
        (window as any)._linkedin_partner_id = "5236970";
        (window as any)._linkedin_data_partner_ids = (window as any)._linkedin_data_partner_ids || [];
        (window as any)._linkedin_data_partner_ids.push((window as any)._linkedin_partner_id);

        (function (l: any) {
            if (!l) {
                (window as any).lintrk = function (a: any, b: any) {
                    (window as any).lintrk.q.push([a, b]);
                };
                (window as any).lintrk.q = [];
            }
            const s = document.getElementsByTagName("script")[0];
            const b = document.createElement("script");
            b.type = "text/javascript";
            b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode?.insertBefore(b, s);
        })((window as any).lintrk);
    }, []);

    return (
        <noscript>
            <img
                height="1"
                width="1"
                style={{ display: "none" }}
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=5236970&fmt=gif"
            />
        </noscript>
    );
};

export default LinkedInInsightTag;
