import { createContext } from 'react'
import { ConsentSettings } from './cookieService'

type CookieBannerContextType = {
  isModalOpen: boolean
  consentSettings?: ConsentSettings
  openModal?: () => void
  closeModal?: () => void
}

export const CookieBannerContext = createContext<CookieBannerContextType>({ isModalOpen: false })
