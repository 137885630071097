import posthog from 'posthog-js'

declare global {
  interface Window {
    dataLayer: any[]

    showCookieBanner: any
  }
}

const cookieProviders = {
  postHog: {
    accept: (consentSettings: ConsentSettings) => {
      if (consentSettings?.analytics) {
        posthog?.opt_in_capturing()
      }
    },
    decline: () => {
      //  tracking code should be deleted from clients storage after opting out
      posthog?.opt_out_capturing()
    },
  },
  google: {
    accept: (consentSettings: ConsentSettings) => {},
    decline: () => {},
  },
  hubspost: {
    accept: (consentSettings: ConsentSettings) => {
      if (consentSettings?.analytics && consentSettings?.marketing) {
        const _hsp = (window._hsp = window._hsp || [])
        _hsq.push(['doNotTrack', { track: true }])
      }
    },
    decline: () => {
      const _hsp = (window._hsp = window._hsp || [])
      _hsq.push(['doNotTrack'])
    },
  },
}

// TODO: (Optional) make these promise based and report error to user if something went wrong while trying to disable
//        and log error to error tracking service

export const updateProviders = (consentSettings: ConsentSettings) => {
  for (const [provider, { accept }] of Object.entries(cookieProviders)) {
    try {
      accept(consentSettings)
    } catch (e) {
      console.log('something went wrong with enabling cookie consent with ', provider)
    }
  }
}

export const disableCookies = () => {
  for (const [provider, { decline }] of Object.entries(cookieProviders)) {
    try {
      decline()
    } catch (e) {
      console.log('something went wrong with disabling cookie consent with ', provider)
    }
  }
}

export type ConsentSettings = {
  // we do this in the next iteration
  marketing: boolean
  analytics: boolean
  // functional: boolean
}

export const readConsentFromClientStorage: () => ConsentSettings | undefined = () => {
  const adCookie = getCookie('advertisement-consent')
  const analyticsCookie = getCookie('analytics-consent')

  if (!adCookie || !analyticsCookie) {
    return undefined
  }

  return {
    marketing: adCookie === 'true' ? true : false,
    analytics: analyticsCookie === 'true' ? true : false,
    // functional: true,
  }
}

const getCookie = name => {
  var cookies = document.cookie.split(';')
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim()
    // Check if this cookie has the desired name
    if (cookie.startsWith(name + '=')) {
      // Extract and return the cookie value
      return cookie.substring(name.length + 1)
    }
  }
  // If the cookie is not found, return null or an empty string
  return null
}

export function setCookie(name: string,value: string, days : number) {
  let expires = "";
  if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
