import { useEffect, useState } from 'react'
import { ConsentSettings, disableCookies } from './cookieService'
import { useTrackingContext } from '../../lib/context/TrackingContext'
import { DataLayerEvents } from '../../lib/context/TrackingContext/interfaces'

export const useCookieBanner = (currentConsentSettings: ConsentSettings | undefined) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { triggerEvent } = useTrackingContext()


  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    if (!currentConsentSettings) {
      // temporaraly disable inital cookie banner showing
      // disableCookies()
      // openModal()
    }

    // to allow triggering from GTM
    window.showCookieBanner = openModal

    triggerEvent(DataLayerEvents.COOKIE_BANNER_READY);
  }, [])

  return {
    isModalOpen,
    openModal,
    closeModal,
    consentSettings: currentConsentSettings,
  }
}
