import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { createContext, useCallback, useMemo } from 'react'
import { SbLink, TranslatedSlug } from '../../../types'
import { I18nContextValue, LinkList, TranslatedLinkList, Translation, TranslationKey } from './interfaces'
import { getTranslatedSlugForPage, stripOutHomeForRootPath } from '../../utils/translatedSlugs'
import { defaultLanguage, INTERNAL_LANGUAGES, LANGUAGE } from '../../../../config/storyblok/languages'
import { formatPath } from '../../utils/internalLinks'

const I18nQuery = graphql`
  {
    datasource: allStoryblokDatasourceEntry(filter: { data_source: { eq: "strings" } }) {
      nodes {
        value
        name
        data_source_dimension
        dimension_value
      }
    }
    links: allStoryblokLink {
      nodes {
        slug
        uuid
        alternates {
          lang
          name
          path
        }
      }
    }
  }
`

export const I18nContext = createContext<I18nContextValue>({
  currentLanguage: defaultLanguage,
  translations: {},
  allLanguages: INTERNAL_LANGUAGES,
  translatedSlugsForPage: null,
  defaultSlug: '',
  getTranslatedUrlFromLink: () => '',
  getTranslatedUrlFromBlok: () => '',
})

export const I18nProvider = ({
  pageLanguage,
  translatedSlugs,
  children,
  defaultSlug,
}: {
  pageLanguage: string
  children: JSX.Element
  translatedSlugs?: TranslatedSlug[]
  defaultSlug: string
}) => {
  const allLanguages: LANGUAGE[] = INTERNAL_LANGUAGES
  const queryResult = useStaticQuery(I18nQuery)

  const currentLanguage = pageLanguage === 'default' ? LANGUAGE.EN : pageLanguage
  const { datasource, links } = queryResult as {
    links: LinkList
    datasource: any
  }

  const translatedSlugsForPage = useMemo<Record<LANGUAGE, string>>(() => {
    let translatedPathsMap = {}

    translatedPathsMap[defaultLanguage] = stripOutHomeForRootPath(defaultSlug)

    translatedSlugs?.forEach(slug => {
      translatedPathsMap[slug.lang] = getTranslatedSlugForPage(slug.lang, translatedSlugs)
    })

    return translatedPathsMap as Record<LANGUAGE, string>
  }, [translatedSlugs])

  // get translations from storyblok data source
  const translations = useMemo(() => {
    const mappedTranslations: Record<LANGUAGE, Translation> | {} = {}

    if (datasource) {
      datasource?.nodes?.forEach(source => {
        let key = source.data_source_dimension || defaultLanguage
        let value = source.dimension_value || source.value

        if (!mappedTranslations[key]) {
          mappedTranslations[key] = {}
        }
        mappedTranslations[key][source.name] = value
      })
    }
    return mappedTranslations
  }, [datasource])

  const translatedLinks = useMemo<TranslatedLinkList>(() => {
    const mappedLinks: TranslatedLinkList = {}

    if (links) {
      links?.nodes.forEach(source => {
        let key = source.uuid

        mappedLinks[key] = {
          defaultSlug: source.slug,
          translatedSlugs: source.alternates,
        }
      })
    }

    return mappedLinks
  }, [links])

  const getTranslatedUrlFromLink = useCallback(
    (link: SbLink) => {
      switch (link?.linktype) {
        case 'url': {
          return link?.url
        }
        case 'asset': {
          return formatPath(link?.cached_url)
        }
        case 'email': {
          return `mailto:${link?.cached_url}`
        }
        case 'story':
        default: {
          const currentLinkItem = translatedLinks[link?.id]

          if (!currentLinkItem) return

          const translatedPath =
            getTranslatedSlugForPage(currentLanguage, currentLinkItem.translatedSlugs) ||
            formatPath(currentLinkItem.defaultSlug)

          return link?.anchor ? `${translatedPath}#${link?.anchor}` : translatedPath
        }
      }
    },
    [currentLanguage, translatedLinks],
  )

  const getTranslatedUrlFromBlok = (fullSlug: string, translatedSlugs: TranslatedSlug[]) =>
    currentLanguage === defaultLanguage
      ? formatPath(fullSlug)
      : getTranslatedSlugForPage(currentLanguage, translatedSlugs)

  return (
    <I18nContext.Provider
      value={{
        allLanguages,
        currentLanguage,
        defaultSlug,
        getTranslatedUrlFromBlok,
        getTranslatedUrlFromLink,
        translatedSlugsForPage,
        translations,
      }}
    >
      {children}
    </I18nContext.Provider>
  )
}

export const useI18n = () => React.useContext(I18nContext)

export const useCurrentTranslations = (): Translation | null => {
  const { currentLanguage, translations } = useI18n()

  return translations[currentLanguage] || null
}

export const useTranslationString = (key: string) => {
  const translations = useCurrentTranslations()

  return translations?.[key] || key
}

export const useCurrentLanguageKey = () => {
  const { currentLanguage } = useI18n()

  return currentLanguage
}

export const useTranslation = () => {
  const translations = useCurrentTranslations()

  const t = React.useCallback(
    (key: TranslationKey | string) => (translations ? translations[key] || key : key),
    [translations],
  )

  return t
}

export const useTranslatedLinksFromBlok = () => {
  const { getTranslatedUrlFromBlok } = useI18n()

  return getTranslatedUrlFromBlok
}
