import { TranslatedSlug } from '../../types'
import { urlArrayToString, urlStringToArray } from './internalLinks'

export const getTranslatedSlugs = (
  lang = '',
  defaultSlug = '',
  translatedSlugs: TranslatedSlug[] | null,
): string | undefined => {
  let slugToBeReturned: string[] = []

  if (!lang) return

  const isDefaultLang = lang?.toLowerCase() === 'default'
  const hasTranslatedSlugs = translatedSlugs && translatedSlugs.length > 0
  const hasLanguageIncluded = hasTranslatedSlugs && translatedSlugs.find(slug => slug?.lang === lang)

  if (isDefaultLang || !hasTranslatedSlugs || !hasLanguageIncluded) {
    slugToBeReturned.push(stripOutHomeForRootPath(defaultSlug))
  } else {
    slugToBeReturned.push(lang || '')
    const translatedSlug = translatedSlugs.find(slug => slug?.lang === lang)
    const newArrayPath = pathWithoutHomeAsArray(translatedSlug.path)
    if (newArrayPath) {
      slugToBeReturned.push(...newArrayPath)
    }
  }

  return urlArrayToString(slugToBeReturned) || '/'
}

export const getTranslatedSlugForPage = (lang?: string, translatedSlugs?: TranslatedSlug[]): string | undefined => {
  let slugToBeReturned: string[] = ['']

  if (!translatedSlugs || !lang) return

  const translatedSlug = translatedSlugs.find(slug => slug?.lang === lang)

  if (!translatedSlug) return

  if (translatedSlug.path) {
    slugToBeReturned.push(lang)

    const newArrayPath = pathWithoutHomeAsArray(translatedSlug.path)

    if (newArrayPath) {
      slugToBeReturned.push(...newArrayPath)
    }

    const newSlug = urlArrayToString(slugToBeReturned)
    return newSlug.length !== 0 ? newSlug : '/'
  }
}

export const pathWithoutHomeAsArray = (path: string) => {
  const newPath = stripOutHomeForRootPath(path)
  return urlStringToArray(newPath)
}

export const stripOutHomeForRootPath = (slug?: string) => {
  if (!slug) return
  return slug.replace('home', '')
}
