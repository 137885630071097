// @ts-ignore
import React, { createContext, useContext, useReducer } from 'react'
import { processDatalayerPayload } from '../../utils/tracking'
import { DataLayerEvents, DataLayerVariables, TrackingActions, TrackingContextValues } from './interfaces'
import {
  getContactFormSubmittedPayload,
  getLicenseKeyLoadedPayload,
  getNewsletterSubscribedPayload,
  getPageViewPayload,
  getProductKitDownloadedPayload,
  getProductKitViewedPayload,
  getUserLoggedInPayload,
  getUserSignedUpPayload,
} from './events'
import { trackingReducer } from './reducer'

const initialValues: TrackingContextValues = {
  clearAllTrackingVariables: () => { },
  clearTrackingVariable: () => { },
  setTrackingVariables: () => { },
  triggerEvent: () => { },
  variables: {},
}

export const Index = createContext<TrackingContextValues>(initialValues)

export const useTrackingContext = () => useContext(Index)

const initialTrackingState = {
  isReady: true,
  variables: {},
}

export const TrackingProvider = ({ children }: { children: React.ReactNode }) => {
  const [trackingState, dispatch] = useReducer(trackingReducer, initialTrackingState)

  const setTrackingVariables = payload => {
    const processedPayload = processDatalayerPayload(payload)
    dispatch({ type: TrackingActions.SET_VARIABLES, payload: processedPayload })
  }

  const clearTrackingVariable = (variable: DataLayerVariables) => {
    dispatch({ type: TrackingActions.CLEAR_TRACKING_VARIABLE, payload: variable })
  }

  const getPayloadForEvent = (event) => {
    const payloadGetterMap = {
      [DataLayerEvents.CONTACT_FORM_SUBMITTED]: getContactFormSubmittedPayload,
      [DataLayerEvents.LICENSE_KEY_DOWNLOADED]: getLicenseKeyLoadedPayload,
      [DataLayerEvents.NEWSLETTER_SUBSCRIBED]: getNewsletterSubscribedPayload,
      [DataLayerEvents.PAGE_VIEW]: getPageViewPayload,
      [DataLayerEvents.PRODUCT_KIT_DOWNLOADED]: getProductKitDownloadedPayload,
      [DataLayerEvents.PRODUCT_KIT_VIEWED]: getProductKitViewedPayload,
      [DataLayerEvents.USER_LOGGED_IN]: getUserLoggedInPayload,
      [DataLayerEvents.USER_SIGNED_UP]: getUserSignedUpPayload,
      [DataLayerEvents.GRANT_AD_CONSENT]: () => ({ [DataLayerVariables.EVENT]: 'advertisement__grant_consent' }),
      [DataLayerEvents.REJECT_AD_CONSENT]: () => ({ [DataLayerVariables.EVENT]: 'advertisement__reject_consent' }),
      [DataLayerEvents.GRANT_ANALYTICS_CONSENT]: () => ({ [DataLayerVariables.EVENT]: 'analytics__grant_consent' }),
      [DataLayerEvents.REJECT_ANALYTICS_CONSENT]: () => ({ [DataLayerVariables.EVENT]: 'analytics__reject_consent' }),
      [DataLayerEvents.COOKIE_BANNER_READY]: () => ({ [DataLayerVariables.EVENT]: 'cookie-banner-ready' }),
    }

    if (!payloadGetterMap[event]) {
      return () => ({
        [DataLayerVariables.EVENT]: event,
      });
    }

    return payloadGetterMap[event];
  }
  const triggerEvent = (event: DataLayerEvents): void => {
    if (event) {

      dispatch({
        type: TrackingActions.TRIGGER_EVENT, payload: getPayloadForEvent(event),
      })
    }
  }

  const clearAllTrackingVariables = () => {
    dispatch({ type: TrackingActions.CLEAR_TRACKING_VALUES })
  }

  const contextValues: TrackingContextValues = {
    clearAllTrackingVariables,
    clearTrackingVariable,
    setTrackingVariables,
    triggerEvent,
    variables: trackingState.variables,
  }

  return <Index.Provider value={contextValues}>{children}</Index.Provider>
}
